import React from 'react'
// style
import imgSobreDetail from '../../../images/imgSobreDetail.png'
import { Container } from './style'
// data
import { data } from '../../../data/dataSobre'

const Texts = () => {
  return (
    <Container>
      <div className='textos'>
        { data.map( (data) => 
          <p>
            <span>{data.title}</span>
            {data.text}
          </p>
        )}
      </div>
      {/* <div className='image'/> */}
    </Container>
  )
}

export default Texts