export const data = [
  {
    title: 'Graduada em Pedagogia',
    text: `, Aprimoramento em Educação Terapêutica e Pedagogia Waldorf, Especialização em Psicopedagogia Clínica e 
            Institucional e Desenvolvimento Infantil - Screener Denver II - Rastreio do Desenvolvimento Infantil. 
            Especialização  em Arteterapia e Criatividade. Associada à ABPp 13671 e AATESP 733/0121`
  },
  {
    title: 'Mãe de quatro filhos',
    text: `, Atuou por 10 anos na educação infantil na instituição- Pedagogia  Waldorf. Ofereceu cursos de férias 
            para crianças no quintal de sua casa durante 3 anos. Atuou como vice diretora e professora de uma 
            instituição particular de educação de jovens e adultos, lecionou aulas de ensino religioso e trabalhos 
            manuais para Ensino Fundamental em instituição escolar.`
  },
  {
    title: '',
    text: `Atua como professora waldorf de Arte-Manuais, ministra workshop de Confecção de Bonecos de pano para 
            Jovens e Adultos, oficinas de iniciação aos trabalhos manuais para todas as idades. Atende e  avalia 
            clinicamente crianças, jovens e adultos com dificuldades na aprendizagem global, resgatando através dos 
            trabalhos artísticos manuais a consciência de potencialidades, capacidades e habilidades em aprender sempre!`
  },
  {
    title: 'Rastreamento do desenvolvimento infantil',
    text: `, oficinas psicopedagógicas e arteterapêuticas,intervenção terapêutica com foco no desenvolvimento de 
            habilidades cognitivas, socio-emocionais e motoras através das artes-manuais e criatividade.`
  },
]



