import styled from 'styled-components'
import imgSobreDetail from '../../../images/imgSobreDetail.png'

export const Container = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  .image {
    margin: 0 auto 100px;
    width: 100%;
    height: 300px;
    background: url(${imgSobreDetail}) no-repeat center center;
    background-size: cover;
  }
  .textos {
    padding: 0 8%;
    p {
      margin-bottom: 50px;
      font-family: 'Marta', serif;
      color: #4e4e4e;
      font-size: 1.1rem;
      span {
        font-size: 1.4rem;
        color: #9e0059;
      } // Span
    } // P
  } // textos
`