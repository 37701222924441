import React from 'react'
// Components
import * as C from '../components'
import FontStyle from '../fonts/fontStyle'
import BackimageSobre from '../images/imgHero-sobre.png'
// Containers
import Texts from '../containers/sobre/Texts'

const data = {
  titleI: 'Sobre',
  titleII: 'Mim',
  text: 'Conheça melhor a fundadora do Atelier Semente, e veja um poucos suas histórias.',
  primaryButton: 'Instagram',
  secondButton: 'Contato',
}

const Sobre = () => {
  return (
    <>
      <C.SEO
        title='Sobre | Atelier Semente'
        description='Conheça melhor a fundadora do Atelier Semente, e veja um poucos suas histórias.'
      />
      <C.Header/>
      <C.Hero 
        title={data.titleI}
        title2={data.titleII}
        text={data.text}
        primaryButton={data.primaryButton}
        secondButton={data.secondButton}
        image={BackimageSobre}
      />
      <Texts/>
      <C.Footer/>
      <FontStyle/>
      <C.GlobalStyles/>
    </>
  )
}

export default Sobre